import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"


const InternalSearch = (props?: any) => {

  // const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [supplierId, setSupplierId] = useState<Number | null>(null);

  const [InternalSearch, setInternalSearch] = useState<Array<InternalSearch> | null>(null);

  // ToDO: typesフォルダへ移動
  type InternalSearch = {
    id: number
    name: string
    mol_url: string
    mol_img_url: string
    count: number
  }

  const fetchInternalSearch = (supplier_id?: number) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/supplier/${supplier_id}/products/popular`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setInternalSearch(result.res)
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  useEffect(() => {
    if (Number(props.supplier_id)) {
      setSupplierId(Number(props.supplier_id))
      fetchInternalSearch(props.supplier_id)
    }
  }, [props.supplier_id])


  return (
    <div id="sidebar">
      <form className="input-group mb-3" noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); navigate(`/supplier/${supplierId}?q=${keyword}`) }}>
        <input type="text" className="form-control rounded" placeholder="製品名..." aria-describedby="product-search" onChange={(e) => setKeyword(e.target.value)} />
        <button className="btn btn-primary px-2" type="button" id="button-addon2" disabled={keyword ? false : true} onClick={() => navigate(`/supplier/${supplierId}?q=${keyword}`)}>
          <span id="product-search" style={{ fontSize: "0.5rem" }}>この会社内で検索</span>
        </button>
      </form>
    </div>
  )
}

export default InternalSearch;