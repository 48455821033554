import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Pagenate from '../components/Pagenation'
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import './Common.css';
import moment from 'moment';
import 'moment/locale/ja';
import {
  Product,
} from "../types/product"
import ModalMolImage from '../components/ModalMolImage';

const ProductSearch = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const [productGenres, setProductGenres] = useState<Array<ProductGenre> | null>(null);
  const [products, setProducts] = useState<Array<Product> | null>(null);
  const [productNumber, setProductNumber] = useState(0);
  const query = new URLSearchParams( useLocation().search );
  const urlPath = useLocation().pathname

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const params = useParams();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(Number(query.get('p') ?? 1) - 1)

  const productPerPage = 10

  type ProductGenre = {
    genre: string
    count: number
  }

  document.title = "化学品の製品検索 | 化学品検索データベース | CHEMICALBASE(ケミカルベース)"

  useEffect(() => {
    fetchProductGenres()
    fetchProducts()
  }, [params])

  const fetchProductGenres = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/product/genres`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setProductGenres(result.res)
          setProductNumber(result.count)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const fetchProducts = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/product/search?c=${productPerPage}&` + query.toString())
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setProducts(result.res)
          setCount(result.count)
          setShow(false)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const openModal = (url: string) => {
    setModalImage(url)
    setIsOpen(true);
  }

  const pageChange = (data: any) => {
    data.selected === 0 ? query.delete("p") : query.set("p", String(data.selected + 1))
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    fetchProducts()
  }

  const changeGenre = (genre: string) => {
    genre === "" ? query.delete("genre") : query.set("genre", genre)
    query.delete("p")
    setCurrentPage(0)
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    fetchProducts()
  }

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main" className="container d-flex">
        {/* PC用カテゴリリスト */}
        <div id="sidebar" className="p-2 d-none d-sm-block" style={{ minWidth: "17rem" }}>
          <div className="p-2">
            <div className="py-2 border-top bread-link">
              <span onClick={() => changeGenre('')}>すべてのカテゴリ {/*({productNumber})*/}</span>
            </div>
            {productGenres?.map((item: ProductGenre, i: number) => (
              <div className="py-2 border-top bread-link">
                <span onClick={() => changeGenre(item.genre)}>{item.genre} {/*({item.count})*/}</span>
              </div>
            ))}
          </div>
        </div>
        {/* スマホ用カテゴリリスト */}
        <div className="d-block d-sm-none" style={{ zIndex: "100", position: "fixed" }}>
          <button className="btn btn-light py-0 px-1" type="button" onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={faBars} className="text-primary" style={{ cursor: "pointer" }} />
          </button>
          <div style={show ? { display: "block" } : { display: 'none' }} className="bg-white">
            <div className="p-2">
              <div className="py-1 border-top bread-link">
                <span onClick={() => changeGenre('')}>すべてのカテゴリ {/*({productNumber})*/}</span>
              </div>
              {productGenres?.map((item: ProductGenre, i: number) => (
                <div className="py-1 border-top bread-link">
                  <span onClick={() => changeGenre(item.genre)}>{item.genre} {/*({item.count})*/}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id="content" className="p-2 w-100">
          <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2 pl-4 pl-sm-0">製品名検索 { query.get('q') ? (<>("{query.get('q')}"の検索結果）</>) : ""}</h4>
          <div className="text-center mt-5">
            <ClipLoader
              color="blue"
              loading={!isLoaded}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          {isLoaded ? (products?.length !== 0 ? products?.map((product: Product, i: number) => (
            <div className={"border-bottom mb-1 mb-sm-3" + isLoaded ? '' : 'd-none'}>
              <div id="product-info" className='d-flex'>
                <div className="d-flex p-2 col-4" style={{ maxWidth: "170px", maxHeight: "170px", cursor: "pointer" }}>
                  <img src={product.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ maxWidth: "150px", maxHeight: "150px" }} className="m-auto shadow border" width="100%" height="auto" alt=''
                    onClick={() => openModal(product.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png")} />
                </div>
                <Link className='ml-2 text-dark' style={{ cursor: "pointer" }} to={`/product/${product.id}`}>
                  <h4>{product.name}</h4>
                  <p className="mb-0"><b className="mr-1">会社名:</b>{product.supplier_name}</p>
                  <p className="mb-0"><b className="mr-1">化合物名:</b>{product.main_compound_name}</p>
                  <p className="mb-0"><b className="mr-1">カテゴリー:</b>{product.genres?.map((item: string, i: number) => (<>{i ? " / " : ""}{item}</>))}</p>
                  <p className="mb-0"><b className="mr-1">グレード:</b>{product.grade}</p>
                  <p className="mb-0"><b className="mr-1">純度:</b>{product.compound_name}</p>
                  <p className="mb-0"><b className="mr-1">荷姿:</b>{product.packing?.map((item: string, i: number) => (<>{i ? " / " : ""}{item}</>))}</p>
                  <p className="mb-1 mb-sm-3 desc2line"><b className="mr-1">概要:</b>{product.description}</p>
                </Link>
              </div>
            </div>
          )): (<p>見つかりませんでした</p>)) : ""}
          <div className={isLoaded ? 'mx-auto' : 'd-none'} >
            <Pagenate
              pageCount={Math.ceil(count / productPerPage)}
              onPageChange={pageChange}
              forcePage={currentPage}
            />
          </div>
        </div>
        <ModalMolImage isOpen={modalIsOpen} setIsOpen={setIsOpen} imageUrl={modalImage} />
      </main>
      <Footer />
    </>
  );
}

export default ProductSearch;