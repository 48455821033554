import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';
import 'moment/locale/ja';
import Pagenate from '../components/Pagenation'

const News = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setItems] = useState([]);
  const [result, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [count, setCount] = useState(5);
  const [currentGenre, setcurrentGenre] = useState("");
  const [currentPage, setcurrentPage] = useState(0);
  const genreArticlePerPage = 14;
  const rankingArticlePerPage = 10;
  const searchArticlePerPage = 10;

  document.title = "最新ニュース一覧 | 化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"

  useEffect(() => {
    showFilteredGenreArticles("化学")
  }, [])

  const pageArticleChange = (data:any) => {
    showFilteredGenreArticles(currentGenre, data.selected)
  }

  const pageRankingChange = (data:any) => {
    showRainkingArticles(currentGenre, data.selected)
  }

  const showFilteredGenreArticles = (genre: string, page:number = 0) => {
    setIsLoaded(true);
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/news/" + genre + (page !== 0 ? ('?c=' + genreArticlePerPage + '&p=' + page) : '?c=' + genreArticlePerPage))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.res);
          setCount(result.count);
          setcurrentGenre(genre);
          setcurrentPage(page);
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const showRainkingArticles = (genre: string, page:number = 0) => {
    setIsLoaded(true);
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/news/ranking" + (page !== 0 ? ('?c=' + rankingArticlePerPage + '&p=' + page) : '?c=' + rankingArticlePerPage))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.res);
          setCount(result.count);
          setcurrentGenre(genre);
          setcurrentPage(page);
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const searchArticles = (data:any = {selected: 0}) => {
    document.getElementById('keyword')?.blur();
    setIsLoaded(false);
    setCount(0);
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/news/search?q=" + searchQuery + (data.selected !== 0 ? ('c=' + searchArticlePerPage + '&p=' + data.selected) : 'c=' + searchArticlePerPage))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setResults(result.res);
          setCount(result.count);
          setcurrentPage(data.selected);
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const clickLink = (url: string):void => {
    const opt ={
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({url: url})
    };

    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/news/viewed", opt)
  }

  const formattedDateTime = (datetime:string) : string => {
    let d = moment(Date.parse(datetime));
    return d.fromNow();
  }

  return (
    <>
      <Header />
      <section id="header_space" />

      <main id="main">

        {/* <!--==========================
          About Us Section
        ============================--> */}
        <section id="news">
          <div className="container">
            <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2">ニュース</h4>
            <div id="genre" className="mb-4">
              <nav className="tabbable">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className="px-2 nav-item nav-link active" id="nav-news-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="true" onClick={() => showFilteredGenreArticles("化学", 0)}>化学</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre1-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("石油", 0)}>石油</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre2-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("食品", 0)}>食品</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre3-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("ゴム", 0)}>ゴム</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre4-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("化粧品", 0)}>化粧品</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre5-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("塗料", 0)}>塗料</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre6-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("製薬", 0)}>製薬</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre7-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("農薬", 0)}>農薬</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre8-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("プラスチック", 0)}>プラスチック</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre9-tab" data-toggle="tab" href="#nav-genre" role="tab" aria-controls="nav-genre" aria-selected="false" onClick={() => showFilteredGenreArticles("繊維", 0)}>繊維</a>
                  <a className="px-2 nav-item nav-link" id="nav-search-tab" data-toggle="tab" href="#nav-search" role="tab" aria-controls="nav-search" aria-selected="false" onClick={() => searchArticles}>検索</a>
                  <a className="px-2 nav-item nav-link" id="nav-ranking-tab" data-toggle="tab" href="#nav-ranking" role="tab" aria-controls="nav-ranking" aria-selected="false" onClick={() => showRainkingArticles("ranking", 0)}>ランキング</a>
                </div>
              </nav>
              <div className="tab-content" id="navTabContents">
                <div className="tab-pane fade show active py-2" id="nav-genre" role="tabpanel" aria-labelledby="nav-genre-tab">
                  <div className='row d-flex'>

                  {data.map((article:any, i:any) => (
                    <div key={article.id} className="col-12 col-lg-6">
                      <div className="d-flex justify-content-between">
                        <div className="mr-2">
                          <h6>
                            <a href={article.source_url} target={article.id} className="text-dark" onClick={() => clickLink(article.source_url)}><b>{article.title}</b></a><br />
                            <small>{formattedDateTime(article.post_at)} / {article.news_source}</small>
                            { article.tags && article.tags.map((tag:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{tag}</span>)) }
                          </h6>
                        </div>
                        {article.image_url ? (<img src={article.image_url} alt="写真" height="60px" />) : ''}
                      </div>
                      <hr className={(data.length -1 === i) ? 'd-none' : 'm-2'} />
                    </div>
                    ))}
                  </div>

                  <Pagenate className="mx-auto"
                    pageCount={Math.ceil(count / genreArticlePerPage)}
                    onPageChange={pageArticleChange} //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                    forcePage={currentPage}
                  />

                </div>

                <div className="tab-pane fade" id="nav-search" role="tabpanel" aria-labelledby="nav-search-tab">
                  <div id="search" className="mb-4">
                    <h5 className="text-dark font-weight-bold border-bottom border-primary mt-4 pb-2">検索</h5>
                    <div className="input-group input-group-sm mb-3 mx-auto col-12 col-lg-6 align-items-center">
                      <b className="mx-2">検索キーワード:</b>
                      <form className="input-group mb-3" noValidate autoComplete="off" onSubmit={(e) => {e.preventDefault();setcurrentPage(0);searchArticles()}}>
                        <input type="text" id="keyword" className="form-control" onChange={(e) => setSearchQuery(e.target.value)}/>
                        <button type="submit" 
                          className="btn btn-primary btn-sm ml-3 px-4 no-wrap"
                          style={{minWidth: "5rem"}} 
                          onClick={() => {setcurrentPage(0);searchArticles()}}>検索
                        </button>
                      </form>
                    </div>
                  </div>
                  {result && result.map((article:any, i:any) => (
                    <div className="my-3" key={article._source.id}>
                      <div className='d-flex justify-content-between'>
                        <div className='mr-2'>
                          <h6 className="mb-1">
                            <a href={article._source.source_url} target={article._source.id} className="text-dark"><b>{article._source.title}</b></a><br />
                            <small>{formattedDateTime(article._source.post_at)} / {article._source.news_source}</small>
                            { article._source.tags && article._source.tags.map((tag:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{tag}</span>)) }
                          </h6>
                        </div>
                        {article.image_url ? (<img src={article.image_url} alt="写真" height="60px" />) : ''}
                      </div>
                      <hr className={'dark ' +(result.length -1 === i) ? 'd-none' : ''} />
                    </div>
                  ))}

                  <div className='text-center'>
                    <ClipLoader color={"#0000FF"} loading={!isLoaded} size={50}/>
                  </div>

                  { result.length ? (
                    <Pagenate 
                      pageCount={Math.ceil(count / searchArticlePerPage)}
                      onPageChange={searchArticles} //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                      forcePage={currentPage}
                    />
                  ) : ''}
                </div>

                <div className="tab-pane fade show py-2" id="nav-ranking" role="tabpanel" aria-labelledby="nav-ranking-tab">
                  <h5 className="text-dark font-weight-bold border-bottom border-primary mt-4 pb-2">アクセスランキング</h5>
                  {data.map((article:any, i:any) => (
                  <div key={i}>
                    <div className='d-flex justify-content-between'>
                      <div className='mr-2'>
                        <h6 className="mb-1">
                          <a href={article.source_url} target={article.id} className="text-dark" onClick={() => clickLink(article.source_url)}>
                            <b>{(currentPage * rankingArticlePerPage + i)  + 1}. {article.title}</b>
                          </a><br />
                          <small>{formattedDateTime(article.post_at)} / {article.news_source}</small>
                          { article.tags && article.tags.map((tag:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{tag}</span>)) }
                        </h6>
                      </div>
                      {article.image_url ? (<img src={article.image_url} alt="写真" height="60px" />) : ''}

                    </div>
                    <hr className={(data.length -1 === i) ? 'd-none' : ''} />
                  </div>
                  ))}

                  <Pagenate className="mx-auto"
                    pageCount={Math.ceil(count / rankingArticlePerPage)}
                    onPageChange={pageRankingChange} //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                    forcePage={currentPage}
                  />

                </div>

              </div>
            </div>

          </div>
        </section>
        {/* <!-- #about --> */}

      </main>
      <Footer />
    </>
  );
}

export default News;