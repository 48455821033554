import React, { useState } from 'react';
import TabNav from './TabNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"

import Modal from 'react-modal'


const Header = () => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      transition: 'opacity 200ms ease-in-out',
      zIndex: 4
    },
  };


  const navigate = useNavigate()
  // const params = useParams()
  const query = new URLSearchParams( useLocation().search )
  // interface State {
  //   defaultKeyword: string|null|undefined
  //   defaultSearchType: string|null
  // }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation()
  console.log("location:::",location.pathname)
  // const {defaultKeyword, defaultSearchType} = location.state as State ?? {defaultKeyword: params.q, defaultSearchType: 'product'}
  const [keyword, setKeyword] = useState(query.get('q') ?? "")
  const [searchType, setSearchType] = useState(() => {
    if( /\/supplier.*/.test(location.pathname) ) {
      return 'supplier'
    } else if ( /\/compound.*/.test(location.pathname) ) {
      return 'compound'
    } else if ( /\/(search|news|event|society).*/.test(location.pathname) ) {
      return 'news'
    }
    
    return 'product'
  })
  console.log(searchType)
  const [menuToggle, setMenuToggle] = useState(false)

  const search = () => {
    console.log("searchType:::",searchType)
    if(searchType === "news") {
      navigate(`/search?q=${keyword}`, {state: {defaultKeyword: keyword, defaultSearchType: searchType}})
    } else if (searchType === "product") {
      navigate(`/product?q=${keyword}`, {state: {defaultKeyword: keyword, defaultSearchType: searchType}})
    } else if (searchType === "compound") {
      navigate(`/compound?q=${keyword}`, {state: {defaultKeyword: keyword, defaultSearchType: searchType}})
    }
  }

  return (
    <header id="header" className="fixed-top">
      <div className="d-none d-md-block">
        <TabNav />
      </div>
      {/* <!-- #header --> */}
        <div className="container-lg ">

          <div className="logo float-left">
            <Link to="/" className="scrollto d-none d-sm-block"><img src="/img/logo.png" alt="" className="img-fluid" /></Link>
            <Link to="/" className="scrollto d-block d-sm-none"><img src="/img/logo_for_smartphone.png" alt="" className="img-fluid" /></Link>
          </div>
          <FontAwesomeIcon className="d-sm-none" id="menuSearch" icon={faSearch} onClick={() => setIsModalOpen(!isModalOpen)}/>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {setIsModalOpen(false)} }
            style={customStyles}
          >
            <form className="input-group mb-3 d-block" noValidate autoComplete="off" onSubmit={(e) => {e.preventDefault();setIsModalOpen(false);search()}}>
              <div>
                <select className="custom-select mb-2" onChange={(e) => { setSearchType(e.target.value)} } defaultValue={searchType}>
                  <option value="product">製品名検索</option>
                  <option value="compound">化合物名検索</option>
                  <option value="news">ニュース/イベント/学会</option>
                </select>
              </div>
              <div className="d-flex">
                <input type="text" id="spSearchWord" className="px-1 mr-n2" placeholder="検索ワード..." defaultValue={keyword} onChange={(e) => setKeyword(e.target.value)} />
                <button className="btn btn-primary px-2" type="button" id="button-addon2" onClick={() => {setIsModalOpen(false);search()}} disabled={keyword ? false : true}><FontAwesomeIcon icon={faSearch} /></button>
              </div>
            </form>

          </Modal>


          <div id="menuToggle" className="d-block d-md-none">
            <input type="checkbox" className="toggle" onClick={() => menuToggle ? setMenuToggle(false) : setMenuToggle(true)} checked={menuToggle} />
            
            <span></span>
            <span></span>
            <span></span>
            
            <ul id="menu">
              <li className=" w-75">
                <form className="input-group mb-3 d-block" noValidate autoComplete="off" onSubmit={(e) => {e.preventDefault();setMenuToggle(false);search()}}>
                  <div>
                    <select className="custom-select mb-2" onChange={(e) => { setSearchType(e.target.value)} } defaultValue={searchType}>
                      <option value="product">製品名検索</option>
                      <option value="compound">化合物名検索</option>
                      <option value="news">ニュース/イベント/学会</option>
                    </select>
                  </div>
                  <div className="d-flex">
                    <input type="text" id="spSearchWord" className="px-1 mr-n2" placeholder="検索ワード..." defaultValue={keyword} onChange={(e) => setKeyword(e.target.value)} />
                    <button className="btn btn-primary px-2" type="button" id="button-addon2" onClick={() => {setMenuToggle(false);search()}} disabled={keyword ? false : true}><FontAwesomeIcon icon={faSearch} /></button>
                  </div>
                </form>
              </li>
              {/* <Link className="my-2 text-dark" to='/login'>ログイン</p> */}
              {/* <Link className="my-2 text-dark" to='/register'>会員登録</p> */}
              <p className="my-2"><Link className="text-dark" to='/product'>化学品データベース</Link></p>
              <p className="my-2"><FontAwesomeIcon icon={faAngleRight} className="ml-3 mr-1" /><Link className="text-dark" to='/product'>製品名検索</Link></p>
              <p className="my-2"><FontAwesomeIcon icon={faAngleRight} className="ml-3 mr-1" /><Link className="text-dark" to='/compound'>化合物名検索</Link></p>
              <p className="my-2"><FontAwesomeIcon icon={faAngleRight} className="ml-3 mr-1" /><Link className="text-dark" to='/supplier'>提供会社一覧</Link></p>
              <p className="my-2"><Link className="text-dark" to='/news'>ニュース</Link></p>
              <p className="my-2"><Link className="text-dark" to='/event'>イベント検索</Link></p>
              <p className="my-2"><Link className="text-dark" to='/society'>学会検索</Link></p>
            </ul>
            <div className="cover"></div>
          </div>

          <nav className="main-nav float-right d-none d-md-block">
            <ul>
              <li className="">
                <form className="input-group" noValidate autoComplete="off" onSubmit={(e) => {e.preventDefault();document.getElementById('searchWord')?.blur();search()}}>
                  <select className="form-select rounded-left small border border-primary" aria-label="" defaultValue={searchType}
                          onChange={(e) => { sessionStorage.setItem('searchType', e.target.value);setSearchType(e.target.value)} }>
                    <option value="product">製品名検索</option>
                    <option value="compound">化合物名検索</option>
                    <option value="news">ニュース/イベント/学会</option>
                  </select>
                <input type="text" id="searchWord" className="form-control px-1 border border-primary border-right-0" defaultValue={keyword} style={{ width: "18vw" }} placeholder="検索ワード..." onChange={(e) => setKeyword(e.target.value)} />
                <button className="btn btn-primary px-3" style={{ zIndex: "1" }} type="submit" id="button-addon2" onClick={() => { search() }} disabled={keyword ? false : true}>検索</button>
                </form>
              </li>
            <li className=""><a className="px-1 px-lg-3" href="/login">ログイン</a></li>
            <li className=""><a className="px-1 px-lg-3" href="/register">会員登録</a></li>
            </ul>
          </nav>
          {/* <!-- .main-nav --> */}

        </div>
      {/* <!-- /header --> */}
    </header>
  );
};

export default Header;