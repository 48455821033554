import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

import ClipLoader from "react-spinners/ClipLoader";
import Pagenate from '../components/Pagenation'
import 'moment/locale/ja';
import { Event } from "../types/event"
import './Common.css';

const Events = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [event, setEvent] = useState<Event | null>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchEvent()
  }, [params])

  document.title = (event?.title ? event.title + "の詳細はこちら | " : "") + "化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"

  const fetchEvent = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + '/api/v1/event/' + params.event_id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setEvent(result.res)
          clickResource("event", Number(params.event_id))
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const clickResource = (resource: string, id: number): void => {
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ resource: resource, id: id })
    };
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/resource/view", opt)
  }

  const formattedDateTime = (datetime: string): string => {
    const d = new Date(Date.parse(datetime));
    return d.getFullYear().toString() + "年 " +
      (d.getMonth() + 1).toString() + "月 " +
      d.getDate().toString() + "日 ";
  }

  const finished = (datetime: string): boolean => {
    const today = new Date(Date.now());
    const end = new Date(Date.parse(datetime));
    return end < today;
  }

  const prefs = [
    '指定なし', '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県',
    '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県',
    '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県',
    '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
    '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県', 'オンライン'
  ]

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main" className="container d-flex my-2">
        {event ? (
          <div id="content" className="p-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-white mb-2">
                <li className="breadcrumb-item bread-link"><Link to={`/event`}>イベント検索</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{event.title}</li>
              </ol>
            </nav>
            {finished(event.end_date) &&
              <div className="text-danger mb-2">※このイベントは終了しました。</div>
            }
            <section id="product-info" className='d-flex'>
              <div className="row px-1">
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">主催名/<span className="d-inline-block">イベント名</span></div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{event.title}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">開催期間</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{formattedDateTime(event.start_date) + "〜 " + formattedDateTime(event.end_date)}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">都道府県</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{prefs[Number(event.pref_cd)]}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">会場</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{event.location}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">概要</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border url">{event.description}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">分野</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{event.genres}</div>
                </div>
              </div>
            </section>

            <section id="event-properties" className="mb-3">
              <div id="space" className="pt-3 pt-sm-0" style={{ marginTop: "10px" }}></div>
              <div className="text-center">
                <a href={event.url} target="_blank" rel="noreferrer" className="btn btn-primary btn-sm ml-3 px-3 no-wrap" style={{ minWidth: "5rem" }}>イベントページへ移動する</a>
              </div>
            </section>

            <section id="event-categories" className="mb-3">
              <h5 className="text-dark border-bottom border-primary pb-2">同じカテゴリのイベント</h5>
              <div>
                <div className='row'>
                  <div className='d-none d-sm-block col-sm-4 col-md-3 text-center'>
                    <b>開催期間</b>
                  </div>
                  <div className='d-none d-sm-block col-sm-8 col-md-4 text-center'>
                    <b>主催名/イベント名</b>
                  </div>
                  <div className='d-none d-md-block col-md-2 text-center'>
                    <b>都道府県</b>
                  </div>
                  <div className='d-none d-md-block col-md-2 text-center'>
                    <b>会場</b>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                {event.category_events?.length ?
                  (event.category_events?.map((event: any, i: any) => (
                    <div className={isLoaded ? '' : 'd-none'}>
                      <div className='row'>
                        <div className='col-12 col-sm-4 col-md-3'>
                          <b className='d-sm-none'>開催期間: </b>
                          {formattedDateTime(event.start_date) + "〜" + formattedDateTime(event.end_date)}
                        </div>
                        <div className='col-12 col-sm-8 col-md-4'>
                          <h6 className="font-weight-bold mb-1">
                            <Link to={`/event/${event.id}`} className="bread-link">{event.title}</Link>
                          </h6>
                        </div>
                        <div className='d-none d-sm-block col-sm-4 d-md-none'>
                        </div>
                        <div className='col-12 col-sm-3 col-md-2'>
                          <b className='d-sm-none'>都道府県: </b>
                          {prefs[Number(event.pref_cd)]}
                        </div>
                        <div className='col-12 col-sm-5 col-md-2'>
                          <b className='d-sm-none'>会場: </b>
                          {event.location}
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))) : (<p>見つかりませんでした</p>)
                }
              </div>
            </section>

            <section id="event-upcoming" className="mb-3">
              <h5 className="text-dark border-bottom border-primary pb-2">直近のイベント</h5>
              <div>
                <div className='row'>
                  <div className='d-none d-sm-block col-sm-4 col-md-3 text-center'>
                    <b>開催期間</b>
                  </div>
                  <div className='d-none d-sm-block col-sm-8 col-md-4 text-center'>
                    <b>主催名/イベント名</b>
                  </div>
                  <div className='d-none d-md-block col-md-2 text-center'>
                    <b>都道府県</b>
                  </div>
                  <div className='d-none d-md-block col-md-2 text-center'>
                    <b>会場</b>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                {event.upcoming_events?.length ?
                  (event.upcoming_events?.map((event: any, i: any) => (
                    <div className={isLoaded ? '' : 'd-none'}>
                      <div className='row'>
                        <div className='col-12 col-sm-4 col-md-3'>
                          <b className='d-sm-none'>開催期間: </b>
                          {formattedDateTime(event.start_date) + "〜" + formattedDateTime(event.end_date)}
                        </div>
                        <div className='col-12 col-sm-8 col-md-4'>
                          <h6 className="font-weight-bold mb-1">
                            <Link to={`/event/${event.id}`} className="bread-link">{event.title}</Link>
                          </h6>
                        </div>
                        <div className='d-none d-sm-block col-sm-4 d-md-none'>
                        </div>
                        <div className='col-12 col-sm-3 col-md-2'>
                          <b className='d-sm-none'>都道府県: </b>
                          {prefs[Number(event.pref_cd)]}
                        </div>
                        <div className='col-12 col-sm-5 col-md-2'>
                          <b className='d-sm-none'>会場: </b>
                          {event.location}
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))) : (<p>見つかりませんでした</p>)
                }
              </div>
            </section>

            <section id="event-footer">
              <div id="space" className="pt-3 pt-sm-0" style={{ marginTop: "10px" }}></div>
              <Link to={`/event`} className="btn btn-primary btn-sm pl-4 pr-3 no-wrap arrow" style={{ minWidth: "5rem" }}>検索結果へ戻る</Link>
            </section>
          </div>
        ) : "" }
      </main >
      <Footer />
    </>
  );
}
export default Events;

// ToDO:書き方揃えた方がいいです。
function formattedDateTime(start_date: string) {
  throw new Error('Function not implemented.');
}
