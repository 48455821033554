import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Pagenate from '../components/Pagenation'
import ClipLoader from "react-spinners/ClipLoader";

import moment from 'moment';
import 'moment/locale/ja';

import {
  // ProductGenre,
  Supplier,
} from "../types/product"

import { category } from '../constants/constants'

const Suppliers = () => {
  document.title = "会社名検索 | 化学品検索データベース | CHEMICALBASE(ケミカルベース)"

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);

  const [suppliers, setSuppliers] = useState<Array<Supplier> | null>(null);
  const params = useParams();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(Number(query.get('p') ?? 0));
  const perPage = 20

  const urlPath = useLocation().pathname
  const [keyword, setKeyword] = useState(query.get('q') ?? '')

  useEffect(() => {
    fetchSupplier()
    setCurrentPage(Number(query.get("p") ?? 1) - 1)
  }, [params])

  const search = () => {
    keyword ? query.set('q', keyword) : query.delete('q')
    query.delete('p')
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
  }

  const pageChange = (data: any) => {
    data.selected === 0 ? query.delete("p") : query.set("p", String(data.selected + 1))
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
    fetchSupplier()
  }

  const fetchSupplier = (genre?: string | null, page?: number | null) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/supplier?c=${perPage}&` + query.toString())
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setSuppliers(result.res)
          setCount(result.count)
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  return (
    <>
      <Header />
      <section id="header_space" />

      <main id="main" className="container my-3 col-12 col-sm-10 col-xl-8">
        <div id="supplier-search" className="ml-auto w-25" style={{ minWidth: "10rem" }}>
          <form className="input-group mb-3" noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); search() }}>
            <input type="text" className="form-control rounded" placeholder="会社名を入力..." value={keyword} aria-describedby="product-search" onChange={(e) => setKeyword(e.target.value)} />
            <button className="btn btn-primary px-2" type="button" id="button-addon2" disabled={keyword ? false : true} onClick={search}>
              <span id="product-search">検索</span>
            </button>
          </form>

        </div>
        <div id="content" className="p-2 w-100">
          <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2">提供会社一覧</h4>
            <div className="text-center mt-5">
              <ClipLoader
                color="blue"
                loading={!isLoaded}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>

          {suppliers?.length !== 0 ? suppliers?.map((supplier: Supplier, i: number) => (
            <div className={isLoaded ? '' : 'd-none'}>
              <Link className="link" to={`/supplier/${supplier.id}`} style={{ color: '#202020', textDecoration: 'none' }}>
                <div id="product-info" className='d-flex mt-2 mt-sm-3 border-bottom'>
                  <div className="d-flex p-2 col-4" style={{ maxWidth: "170px", maxHeight: "170px" }}>
                    <img src={supplier.logo_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ maxWidth: "100%", maxHeight: "100%" }} className="m-auto" alt='' />
                  </div>
                  <div className='ml-2'>
                    <h4 className="mb-1">{supplier.name}</h4>
                    <p className="mb-0"><b className="mr-1">部署:</b>{supplier.division}</p>
                    <p className="mb-0"><b className="mr-1">所在地:</b>{supplier.address}</p>
                    <p className="mb-2 mb-sm-3"><b>業種:</b>{supplier?.category.map((item: string, i: number) => (<span>{category[item]}</span>))}</p>
                  </div>
                </div>
              </Link>
            </div>
          )) : <p>見つかりませんでした</p>

          }
          <p className="mt-2">※掲載されている会社名・ロゴは、各社の登録商標または商標です。</p>

          <div className={isLoaded ? 'mx-auto' : 'd-none'} >
            <Pagenate
              pageCount={Math.ceil(count / perPage)}
              onPageChange={pageChange}
              forcePage={currentPage}
            />
          </div>

        </div>
      </main>

      <Footer />

    </>
  )
}

export default Suppliers;