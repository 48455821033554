import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from "react-router-dom"


const TabNav = () => {

  return (
    <section className="text-white font-weight-bold" style={{ "backgroundColor": '#0B5394' }}>
      <div className="container-lg">
        <ul className="nav" role="tablist" style={{ fontSize: "0.9rem" }}>
          <Link className="mr-auto" to='/'>
            <FontAwesomeIcon icon={faHome} className="bread-link my-2" style={{ color: "white", fontSize:"20px" }} />
          </Link>
          <div className="nav justify-content-end">
          <li className="nav-item dropdown">
            <p className="dropdown-toggle text-white my-2 mx-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">化学品データベース</p>
            <div className="dropdown-menu">
              <Link className="py-1 m-0 dropdown-item" to='/product'>製品名検索</Link>
              <Link className="py-1 m-0 dropdown-item" to='/compound'>化合物名検索</Link>
              <Link className="py-1 m-0 dropdown-item" to='/supplier'>提供会社一覧</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <p className="dropdown-toggle text-white my-2 mx-3" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">各種サービス</p>
            <div className="dropdown-menu">
              <Link className="py-1 m-0 dropdown-item" to='/news'>ニュース</Link>
              {/* <p className="my-2 link" onClick={() => navigate('/papers'>論文</p> */}
              <Link className="py-1 m-0 dropdown-item" to='/event'>イベント検索</Link>
              <Link className="py-1 m-0 dropdown-item" to='/society'>学会検索</Link>
            </div>
          </li></div>
        </ul>
      </div>
    </section>
  )
};

export default TabNav;