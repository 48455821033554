import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom"

const PopularProducts = (props?: any) => {

  // const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [supplierId, setSupplierId] = useState<Number | null>(null);

  const [popularProducts, setPopularProducts] = useState<Array<PopularProducts> | null>(null);

  type PopularProducts = {
    id: number
    name: string
    mol_url: string
    mol_img_url: string
    count: number
    compound_name_jp: string
    purity: number
  }

  const fetchPopularProducts = (supplier_id?: number) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/supplier/${supplier_id}/products/popular`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setPopularProducts(result.res)

        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  useEffect(() => {
    if (Number(props.supplier_id)) {
      setSupplierId(Number(props.supplier_id))
      fetchPopularProducts(props.supplier_id)
    }
  }, [props.supplier_id])


  return (

    <div id="sidebar">
      <h5 className='mb-1 border-bottom'>
        この会社の人気製品
      </h5>

      {popularProducts?.map((item: PopularProducts, i: number) => (
        <div className='d-flex border-bottom py-2'>
          <div className="shadow border d-flex p-2" style={{ maxWidth: "80px", height: "80px" }}>
            <img src={item.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ width: "60px", height: "60px" }} className="m-auto" width="100%" height="auto" alt='' />
          </div>
          <div className="ml-2" style={{ fontSize: "1rem" }}>
            <Link className="mb-1 bread-link" to={`/product/${item.id}`}>{item.name}</Link>
            <p className="small mb-1">{item.compound_name_jp}</p>
            <p className="small">純度:{(item.purity ?? "-") + "%"}</p>
          </div>
        </div>
      ))}

    </div>

  )
}

export default PopularProducts;