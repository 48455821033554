import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import './App.css';
// import Top from './Top';
import News from './pages/News';
import Papers from './pages/Papers';
import Societies from './pages/Societies';
import SocietySearch from './pages/SocietySearch';
import Events from './pages/Events';
import EventSearch from './pages/EventSearch';
import Search from './pages/Search';
import Products from './pages/Products';
import ProductSearch from './pages/ProductSearch';
import CompoundSearch from './pages/CompoundSearch';
import Suppliers from './pages/Suppliers';
import SupplierIndex from './pages/SupplierIndex';
import ReactGA from "react-ga4";
import Corporate from './pages/Corporate';

const App = () => {
  ReactGA.initialize("GTM-MNN3PDW");
  ReactGA.send("pageview");

  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Navigate to="/product" />} />
        <Route path={"/product/:product_id"} element={<Products />} />
        <Route path={"/product"} element={<ProductSearch />} />
        <Route path={"/compound"} element={<CompoundSearch />} />
        <Route path={"/supplier/:supplier_id"} element={<Suppliers />} />
        <Route path={"/supplier"} element={<SupplierIndex />} />
        <Route path={"/news"} element={<News />} />
        {/* <Route path={"/paper"}    element={<Papers />} /> */}
        <Route path={"/society/:society_id"} element={<Societies />} />
        <Route path={"/society"} element={<SocietySearch />} />
        <Route path={"/event/:event_id"} element={<Events />} />
        <Route path={"/event"} element={<EventSearch />} />
        <Route path={"/search"} element={<Search />} />
        <Route path={"/corporate"} element={<Corporate />} />
        {/* <Route path={"/login"}     element={<Top />} /> */}
        {/* <Route path={"/logout"}    element={<Top />} /> */}
        {/* <Route path={"/profile"}   element={<Top />} /> */}
        <Route path={"*"} element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    // <ToastContainer />
  )
};

export default App;