import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Pagenate from '../components/Pagenation'
import ClipLoader from "react-spinners/ClipLoader";
import './Society.css';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';

const Societies = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const query = new URLSearchParams( useLocation().search );
  const params = useParams();
  const urlPath = useLocation().pathname

  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [dir, setDirection] = useState('ASC');
  const [count, setCount] = useState(2);
  const [genre, setGenre] = useState('');
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState(query.get('tg'));
  const [currentPage, setCurrentPage] = useState<number>(Number(query.get('p') ?? 0))
  const societyPerPage = 10;


  document.title = "学会名検索 | 化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"

  useEffect(() => {
    fetchSocieties()
    fetchTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dir, genre, currentPage, tag])



  const fetchSocieties = () => {
    setIsLoaded(false);
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/society${genre}?c=${societyPerPage}&` + query.toString()
      // + '?c=' + societyPerPage
      // + (!keyword ? '' : '&q=' + keyword)
      // + (!tag ? '' : '&tg=' + tag)
      // + (dir !== 'DESC' ? '' : '&dr=' + dir)
      // + (currentPage === 0 ? '' : '&p=' + currentPage)
    )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result.res);
          setCount(result.count);
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const fetchTags = () => {
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/society/tags/" + genre)
      .then(res => res.json())
      .then(
        (result) => {
          setTags(result.res);
        },
        (error) => {
          setError(error);
        }
      )
  }

  const pageChange = (data: any) => {
    data.selected === 0 ? query.delete("p") : query.set("p", String(data.selected + 1))
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
    fetchSocieties()
  }
  
  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main">

        <section id="papers">
          <div className="container">
            <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2">学会検索</h4>
            <div id="search" className="my-4">
              <div className="input-group input-group-sm mb-3 mx-auto col-12 col-sm-6 align-items-center">
                <b className="mx-2">検索キーワード:</b>
                <form className="input-group mb-3" noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); document.getElementById('keyword')?.blur(); fetchSocieties() }}>
                  <input id="keyword" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
                    onChange={(e) => setKeyword(e.target.value)} />
                  <button type="submit" className="btn btn-primary btn-sm ml-3 px-4 no-wrap" style={{ minWidth: "5rem" }} onClick={fetchSocieties}>検索</button>
                </form>
              </div>
            </div>

            <h5 className="text-dark font-weight-bold border-bottom border-primary pb-2">分野別</h5>
            <div id="genre" className="mb-4">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className="px-2 nav-item nav-link active" id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab"
                    aria-controls="nav-all" aria-selected="true" onClick={() => { setCurrentPage(0); setGenre(""); setTags([]); setTag("") }}>全て</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre1-tab" data-toggle="tab" href="#nav-genre1" role="tab"
                    aria-controls="nav-genre1" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("食品"); setTags([]); setTag("") }}>食品</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre2-tab" data-toggle="tab" href="#nav-genre2" role="tab"
                    aria-controls="nav-genre2" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("ゴム"); setTags([]); setTag("") }}>ゴム</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre3-tab" data-toggle="tab" href="#nav-genre3" role="tab"
                    aria-controls="nav-genre3" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("化粧品"); setTags([]); setTag("") }}>化粧品</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre4-tab" data-toggle="tab" href="#nav-genre4" role="tab"
                    aria-controls="nav-genre4" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("塗料"); setTags([]); setTag("") }}>塗料</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre5-tab" data-toggle="tab" href="#nav-genre5" role="tab"
                    aria-controls="nav-genre5" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("製薬"); setTags([]); setTag("") }}>製薬</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre6-tab" data-toggle="tab" href="#nav-genre6" role="tab"
                    aria-controls="nav-genre6" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("農薬"); setTags([]); setTag("") }}>農薬</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre7-tab" data-toggle="tab" href="#nav-genre7" role="tab"
                    aria-controls="nav-genre7" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("プラスチック"); setTags([]); setTag("") }}>プラスチック</a>
                  <a className="px-2 nav-item nav-link" id="nav-genre8-tab" data-toggle="tab" href="#nav-genre8" role="tab"
                    aria-controls="nav-genre8" aria-selected="false" onClick={() => { setCurrentPage(0); setGenre("繊維"); setTags([]); setTag("") }}>繊維</a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                  <div className="readmore">
                    <input id="check1" className="readmore-check" type="checkbox" />
                    <div className="readmore-content">
                      {tags.map((item: string, i: number) => (
                        <button className={"btn btn-sm rounded-pill m-1 " + (item === tag ? "btn-primary" : "btn-outline-primary")}
                          style={{ fontSize: "0.75rem" }}
                          value={item}
                          onClick={(e) => {
                            if (tag !== e.currentTarget.value) {
                              query.set("tg", e.currentTarget.value)
                              query.delete("p")
                              navigate({pathname: urlPath, search: "?" + query.toString() })
                              setTag(e.currentTarget.value)
                            } else {
                              query.delete("tg")
                              query.delete("p")
                              navigate({pathname: urlPath, search: "?" + query.toString() })
                              setTag("")
                            }
                          }}>{item}</button>
                      ))}
                    </div>
                    <label className="readmore-label" htmlFor='check1'></label>
                  </div>



                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center border-bottom border-primary mb-2">
              <h5 className="text-dark font-weight-bold my-0">検索結果</h5>
              <div className="d-flex align-items-center ml-auto input-group input-group-sm mb-1" style={{ width: "15rem" }}>
                <span className="mr-2">並び替え</span>
                <select className="custom-select" id="sort" 
                  onChange={(e) => {
                    setDirection(e.target.value)
                    query.set("dr",e.target.value)
                    navigate({pathname: urlPath, search: "?" + query.toString() })
                  }}>
                  <option value="ASC" selected>タイトル/昇順</option>
                  <option value="DESC">タイトル/降順</option>
                </select>
              </div>
            </div>

            <div id="latest" className="mb-3">
                <div className="text-center mt-5">
                <ClipLoader
                  color="blue"
                  loading={!isLoaded}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>

              {data != undefined && data.length ?
                (data.map((society: any, i: any) => (
                  <div>
                    <h6 className="font-weight-bold mb-1">
                      <Link to={`/society/${society.id}`} target="_blank">{society.name}</Link>
                      {society.genres && society.genres.map((genre: string, i: number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{genre}</span>))}
                    </h6>
                    <p className="ml-3 mb-0 news_body_wrap">
                      <span className="font-weight-bold">概要 : </span>{society.description}
                    </p>
                    <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">事務局 : </span>{society.organizer}</p>
                    <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">設立年度 : </span>{society.since ?? " - "}年</p>
                    <hr />
                  </div>
                ))) : (<p>見つかりませんでした</p>)
              }
              <div className='text-center'>
                <ClipLoader color={"#0000FF"} loading={!isLoaded} size={50} />
              </div>
            </div>
            {data != undefined && data.length ? (
              <Pagenate className="mx-auto"
                pageCount={Math.ceil(count / societyPerPage)}
                onPageChange={pageChange}
                forcePage={currentPage}
              />
              ) : ''
            }

          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}

export default Societies;
