import Modal from 'react-modal'

const ModalMolImage = (props?:any) => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      transition: 'opacity 200ms ease-in-out',
      zIndex: 4
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {props.setIsOpen(false)} }
      style={customStyles}
    >
      <img src={props.imageUrl} style={{width:"100%", height:"100%"}} className="m-auto" width="100%" height="auto" alt='' />

    </Modal>
  )
}

export default ModalMolImage;
