import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Pagenate from '../components/Pagenation'
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons'
import './Common.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Modal from 'react-modal';

import moment from 'moment';
import 'moment/locale/ja';

import {
  Product,
  ProductGenre,
  Compound,
} from "../types/product"
import ModalMolImage from '../components/ModalMolImage';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    transition: 'opacity 200ms ease-in-out',
    zIndex: 4
  },
};

const CompoundSearch = () => {
  // タイトル変更
  document.title = "化合物検索 | 化学品検索データベース | CHEMICALBASE(ケミカルベース)"

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const query = new URLSearchParams( useLocation().search );

  const [compounds, setCompounds] = useState<Array<Compound> | null>(null);
  const params = useParams();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(Number(query.get('p') ?? 0))
  // const [keyword, setKeyword] = useState(query.get('q') ?? '')

  const PerPage = 20
  const urlPath = useLocation().pathname

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalImage, setModalImage] = useState("");
  const [show, setShow] = React.useState(false);
  const [productGenres, setProductGenres] = useState<Array<ProductGenre> | null>(null);
  // const [productNumber, setProductNumber] = useState(0); //製品数表示

  useEffect(() => {
    fetchProductGenres()
    setCurrentPage(Number(query.get("p") ?? 1) - 1)

    fetchCompounds('')
  }, [params])

  const fetchProductGenres = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/product/genres`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setProductGenres(result.res)
          // setProductNumber(result.count) //製品数表示変更
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const pageChange = (data: any) => {
    data.selected === 0 ? query.delete("p") : query.set("p", String(data.selected + 1))
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
    fetchCompounds('')
  }

  const changeGenre = (genre: string) => {
    genre === "" ? query.delete("genre") : query.set("genre", genre)
    query.delete("p")
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
    
    fetchCompounds('')
  }


  const fetchCompounds = (genre?: string | null, page?: number | null) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/compound/search?c=${PerPage}&` + query.toString())
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setCompounds(result.res)
          setCount(result.count)
          setShow(false)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const openModal = (url: string) => {
    setModalImage(url)
    setIsOpen(true)
  }

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main" className="container d-flex col-12 col-sm-10">
        {/* PC用カテゴリリスト */}
        <div id="sidebar" className="p-2 d-none d-sm-block" style={{ minWidth: "17rem" }}>
          <div className="p-2">
            <div className="py-2 border-top bread-link">
              <span onClick={() => changeGenre('')}>すべてのカテゴリ {/* ({productNumber}) */}</span>
            </div>
            {productGenres?.map((item: ProductGenre, i: number) => (
              <div className="py-2 border-top bread-link">
                <span onClick={() => changeGenre(item.genre)}>{item.genre} {/* ({item.count}) */}</span>
              </div>
            ))}
          </div>
        </div>
        {/* スマホ用カテゴリリスト */}
        <div className="d-block d-sm-none" style={{ zIndex: "100", position: "fixed" }}>
          <button className="btn btn-light py-0 px-1" type="button" onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={faBars} className="text-primary" style={{ cursor: "pointer" }} />
          </button>
          <div style={show ? { display: "block" } : { display: 'none' }} className=" bg-white">
            <div className="p-2">
              <div className="py-1 border-top bread-link">
                <span onClick={() => changeGenre('')}>すべてのカテゴリ</span>
              </div>
              {productGenres?.map((item: ProductGenre, i: number) => (
                <div className="py-1 border-top bread-link">
                  <span onClick={() => changeGenre(item.genre)}>{item.genre}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id="content" className="p-2 w-100">
          <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2 pl-4 pl-sm-0">化合物名検索 { query.get('q') ? (<>("{query.get('q')}"の検索結果）</>) : ""}</h4>
          <div className="text-center mt-5">
            <ClipLoader
              color="blue"
              loading={!isLoaded}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          {isLoaded ? compounds?.map((compound: Compound, i: number) => (
            <div className={"border-bottom mb-1 mb-sm-3" + isLoaded ? '' : 'd-none'} >
              <div id="product-info" className='d-flex'>
                <div className="d-flex p-2 col-4" style={{ maxWidth: "170px", maxHeight: "170px" }}>
                  <img src={compound.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ maxWidth: "150px", maxHeight: "150px" }} className="m-auto shadow border" width="100%" height="auto" alt=''
                    onClick={() => openModal(compound.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png")} />
                </div>
                <div className='ml-2'>
                  <h4 className="mb-1">{compound.name_jp}</h4>
                  {/* <p className="mb-1"><b className="mr-1">CAS No.:</b> 159191-56-7</p> */}
                  <p className="mb-0"><b className="mr-1">化学式:</b>{compound.fomula}</p>
                  <p className="mb-0"><b className="mr-1">分子量:</b>{compound.weight}</p>
                  <p className="mb-0 desc2line" style={{ wordBreak: "break-word" }}><b className="mr-1">同義語:</b>{compound.aliases?.map((item: string, i: number) => (<>{i === 0 ? "" : '、'}{item}</>))}</p>
                  <p className="mb-0"><b className="mr-1">提供会社:</b>全て({compound.count.all}) メーカー({compound.count.maker}) 商社({compound.count.trading_company})</p>
                </div>
              </div>
              {/* PC用提供会社 */}
              <div className=" d-none d-sm-block">
                <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="p-1">会社名</th>
                    <th scope="col" className="p-1">製品名</th>
                    <th scope="col" className="p-1">純度</th>
                    <th scope="col" className="p-1">グレード</th>
                    <th scope="col" className="p-1">荷姿</th>
                    <th scope="col" className="p-1">価格</th>
                    <th scope="col" className="p-1">詳細</th>
                  </tr>
                </thead>
                <tbody>
                  {compound.products.map((product: Product, i: number) => (
                    <tr>
                      <td className="p-1">{product.name}</td>
                      <td className="p-1">{product.product_name}</td>
                      <td className="p-1">{product.purity ?? "-"} %</td>
                      <td className="p-1">{product.grade ?? "-"}</td>
                      <td className="p-1">{product.packing && product.packing.map((item: string, i: number) => (<>{item}<br /></>))}</td>
                      <td className="p-1">お問合せください</td>
                      <td className="p-1">
                        <Link to={`/product/${product.id}`}>
                          <FontAwesomeIcon icon={faSearch} className="bread-link" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
              {/* スマホ用提供会社 */}
              <Accordion defaultActiveKey="1" className="mt-2 d-sm-none w-100">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>提供会社</Accordion.Header>
                  <Accordion.Body className="p-2">
                    {compound.products.map((product: Product, i: number) => (

                      <div className="container"><div className="row">
                        <div className='col-12'>
                          <b className='col-3 p-0'>会社名: </b>{product.name}
                        </div>
                        <div className='col-12'>
                          <b className='d-sm-none'>製品名: </b>{product.product_name}
                        </div>
                        <div className='col-12'>
                          <b className='d-sm-none'>純度: </b>{product.purity ?? "-"} %
                        </div>
                        <div className='col-12'>
                          <b className='d-sm-none'>グレード: </b>{product.grade ?? "-"}
                        </div>
                        <div>
                          <b className='d-sm-none'>荷姿: </b>{product.packing && product.packing.map((item: string, i: number) => (<>{item}<br /></>))}
                        </div>
                        <div className='col-12'>
                          <b className='d-sm-none'>価格: </b>お問合せください
                        </div>
                        <div className='col-12 mb-2'>
                          <b className='d-sm-none'>詳細: </b><Link to={`/product/${product.id}`}><FontAwesomeIcon icon={faSearch} className="bread-link"/></Link>
                        </div>
                      </div></div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="text-right small mt-2" />
            </div>
          )) : ""}

          <div className={isLoaded ? 'mx-auto' : 'd-none'} >
            <Pagenate
              pageCount={Math.ceil(count / PerPage)}
              onPageChange={pageChange}
              forcePage={currentPage}
            />
          </div>

          <p className="small">※このサイトの一部情報はNITEより引用しております。</p>

          <ModalMolImage isOpen={modalIsOpen} setIsOpen={setIsOpen} imageUrl={modalImage} />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default CompoundSearch;