import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import ClipLoader from "react-spinners/ClipLoader";
import PopularProducts from '../components/PopularProducts';
import {
  Product,
  ProductGenre,
  Document,
  Supplier,
} from "../types/product"
import { category } from '../constants/constants'

import moment from 'moment';
import 'moment/locale/ja';
import InternalSearch from '../components/InternalSearch';

const Suppliers = () => {

  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [popularDocuments, setPopularDocuments] = useState<Array<Document> | null>(null);
  const navigate = useNavigate();
  const [productGenres, setProductGenres] = useState<Array<ProductGenre> | null>(null);
  const queryStrings = useLocation().search;
  const query = new URLSearchParams(queryStrings);
  // const category: { [index: string]: string; } = { maker: "メーカー", trading_office: "商社"}
  const keyword = query.get('q')
  const params = useParams();

  useEffect(() => {
    fetchSupplier(query.get('genre'))
    fetchPopularDocuments(Number(params.supplier_id))
    fetchProductGenres(Number(params.supplier_id))
  }, [params])

  document.title = (supplier?.name ? supplier.name + "の製品一覧 | " : "") + "化学品検索データベース | CHEMICALBASE(ケミカルベース)"

  const clickResource = (resource: string, id: number): void => {
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ resource: resource, id: id })
    };

    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/resource/view", opt)
  }

  const fetchSupplier = (genre?: string | null) => {
    setIsLoaded(false)
    fetch(`${process.env.REACT_APP_CHEMICALBASE_API_BASEURL}/api/v1/supplier/${params.supplier_id}?` + (genre ? `genre=${genre}&` : '') + (keyword ? `q=${keyword}&` : ''))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setSupplier(result.res)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const fetchPopularDocuments = (supplier_id: number) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/supplier/${supplier_id}/documents/popular`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setPopularDocuments(result.res)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const fetchProductGenres = (supplier_id: number) => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/supplier/${supplier_id}/product/genres`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setProductGenres(result.res)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const setGenreFilter = (genre: string) => {
    navigate(`/supplier/${params.supplier_id}` + (genre !== '' ? `?genre=${genre}` : ''))
  }

  return (
    <>
      <Header />
      <section id="header_space" />
      <section id="company_head" className="container p-0 mt-3">
        <div className="d-flex align-items-center m-2">
          {supplier?.logo_url !== null ? (
            <img src={supplier?.logo_url} alt="logo" style={{ maxHeight: "3rem", maxWidth: "30%" }} />
          ) : ""}
          <h2 className="ml-2 my-0">{supplier?.name}</h2>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white pl-2">
            <li className="breadcrumb-item pointer bread-link"><Link to={`/supplier`}>提供会社</Link></li>
            <li className="breadcrumb-item pointer bread-link"><Link to={`/supplier/${supplier?.id}`}></Link>{supplier?.name}</li>
          </ol>
        </nav>
      </section>
      <main id="main" className="container my-2">
        <div className="row">
          <div className="col-12 col-sm-3 p-2">
            <InternalSearch supplier_id={params.supplier_id} />
            {/* スマホではここに人気商品は表示しない */}
            <div className="d-none d-sm-block">
              <PopularProducts supplier_id={params.supplier_id} />
            </div>
          </div>

          <div id="content" className="col-12 col-sm-9">
            <section id="distributer-info">
              <p><b>業種:</b>{supplier?.category.map((item: string, i: number) => (<span>{category[item]}</span>))}</p>
              <p><b>会社概要:</b>{supplier?.description}</p>
            </section>
            <section id="distributer-genre-filter" className="mb-3">
              <h5 className="mt-2 mb-1">業界で製品を絞り込む</h5>
              <div className="row p-2">
                {productGenres?.map((item: ProductGenre, i: number) => (

                  <div className="p-1 col-3 col-sm-2">
                    <button className="btn btn-secondary rounded p-1 w-100 h-100" onClick={() => setGenreFilter(item.genre)}>
                      {item.genre !== '' ? item.genre : '全ての製品'} <br />
                      {item.count}製品　＞
                    </button>
                  </div>
                ))}
              </div>

            </section>
            <section id="distributer-genre-filter" className="mb-3">
              <h5 className="mt-2 mb-1">製品一覧</h5>
              <div className="row p-2">
                {supplier?.products.map((product: Product, i: number) => (
                  <div className="col-6 col-sm-3 p-1">
                    <div className='shadow border p-2 text-center h-100'>
                      <img src={product.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ width: "120px", height: "120px" }} className="m-auto border " width="100%" height="auto" alt='' />
                      <p className="my-0 small">{product.name}</p>
                      {/* <p className="my-0 small">{product.fomula}</p> */}
                      <Link to={`/product/${product.id}`}><button className="btn btn-sm btn-primary">詳細を見る</button></Link>
                    </div>
                  </div>
                ))}</div>

              {/* <div className="text-right small">
              <a href="#"><p>製品一覧をもっと見る ＞ </p></a>
            </div> */}
            </section>

            {/* スマホではここに人気商品を表示 */}
            <div className="col-12 d-block d-sm-none px-0 mb-3">
              <PopularProducts supplier_id={params.supplier_id} />
            </div>
            <section id="product-documents" className="mb-3">
              <h5 className="mt-2 mb-1">よくみられる資料一覧</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">資料名</th>
                    <th scope="col">種類</th>
                    <th scope="col">製品名</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {popularDocuments?.map((item: Document, i: number) => (
                    <tr>
                      <th scope="row" className="p-1 p-sm-3">
                        {item.name}
                      </th>
                      <td className="p-1 p-sm-3">{item.category}</td>
                      <td className="p-1 p-sm-3">{item.product_name}</td>
                      <td className="p-1 p-sm-3">
                        <a href={item.url} target={item.url} onClick={() => clickResource("document", item.id)}>
                          資料を見る
                        </a>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </section>

          </div>
        </div>
        <p className="mt-2">※掲載されている会社名・ロゴは、各社の登録商標または商標です。</p>
      </main >

      <Footer />

    </>
  );
}

export default Suppliers;