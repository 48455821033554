import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { useLocation } from 'react-router-dom';

import ClipLoader from "react-spinners/ClipLoader";

const Search = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [articles, setArticles] = useState([]);
  const [articleCount, setArticleCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [societies, setSocieties] = useState([]);
  const [societieCount, setSocietieCount] = useState(0);

  const queryStrings = useLocation().search;
  const params = new URLSearchParams(queryStrings);
  const keyword = params.get('q')

  document.title = "検索 | Chemicalbase"


  const location = useLocation();


  useEffect(() => {
    // const keyword = new URLSearchParams(q);
    if(keyword) {
      setIsLoaded(false)
      search(keyword)

    }
  },[location])

  const prefs = [
    '指定なし','北海道','青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県',
    '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県',
    '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県',
    '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
    '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県','オンライン'
  ]

  const search = (searchKeyword:string) => {
    if(keyword)
      fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + '/api/v1/search' + (keyword ? '?q=' + searchKeyword : '') )
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setArticles(result.res.articles.data);
            setArticleCount(result.res.articles.count);
            setEvents(result.res.events.data);
            setEventCount(result.res.events.count);
            setSocieties(result.res.societies.data);
            setSocietieCount(result.res.societies.count);
            window.scrollTo(0, 0);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  }

  const formattedDateTime = (datetime:string) : string => {
    const d = new Date(Date.parse(datetime));
    return  d.getFullYear().toString() + "年 " + 
            (d.getMonth() + 1).toString() + "月 " + 
            d.getDate().toString() + "日 ";
  }

  const clickLink = (url: string):void => {
    const opt ={
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({url: url})
    };

    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/news/viewed", opt)
  }

  // if (error) return <p>Oh no... {error.message}</p>;

  // Change EpochDate to readable format
  // const epochToDate = (epochTime: number) : string => {
  //   const d = new Date(epochTime * 1000);
  //   return d.getFullYear().toString() + "年 " + d.getMonth().toString() + "月 " + d.getDay().toString() + "日";

  // }

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main">

        <section id="papers">
          <div className="container">
            
            <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2">「{keyword}」の検索結果</h4>

            <h5 className="text-dark font-weight-bold border-bottom border-primary pb-2">ニュース</h5>
            <div id="NewsArticleResult" className="mb-4">
              {isLoaded && (articles.length ? articles.map((article:any, i:any) => (
                <div key={i}>
                  <h6 className="mb-1">
                    <a href={article.source_url} target={article.id} className="text-dark" onClick={() => clickLink(article.source_url)}><b>{article.title}</b></a><br />
                    <small>{formattedDateTime(article.post_at)} / {article.news_source}</small>
                    { article.tags && article.tags.map((tag:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{tag}</span>)) }
                  </h6>
                  <p className="mb-1 text-truncate">{article.body ?? ""}</p>
                  <hr className={"my-2 " + (articles.length -1 === i ? 'd-none' : '')} />
                </div>
              )) : <p>見つかりませんでした</p>)
              }
              {/* {articleCount > 5 && <div><a href="#" onClick={() => navigate('/search?q=' + keyword)}>もっと見る</a></div>} */}
              <ClipLoader color={"#0000FF"} loading={!isLoaded} size={50}/>
            </div>

            <h5 className="text-dark font-weight-bold border-bottom border-primary pb-2">イベントカレンダー</h5>
            <div id="EventResults" className="mb-3">
              {isLoaded && (events.length ? events.map((event:any, i:any) => (
                <div key={i} className="d-flex">
                  <h6 style={{width:"17rem"}}>
                    <a href={event.url} target={event.id} className="text-dark"><b>{event.title}</b></a>
                    { event.genres && event.genres.map((genre:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{genre}</span>)) }
                  </h6>
                  <small>{formattedDateTime(event.start_date) + "〜" + formattedDateTime(event.end_date)} / {event.location} - {prefs[Number(event.pref_cd)]}</small>
                </div>
              )) : <p>見つかりませんでした</p>)}
              <ClipLoader color={"#0000FF"} loading={!isLoaded} size={50}/>
            </div>

            <h5 className="text-dark font-weight-bold border-bottom border-primary pb-2">学会</h5>
            <div id="latest" className="mb-3">
              {isLoaded && (societies.length ? 
              societies.map((society:any, i:any) => (
              <div>
                <h6 className="font-weight-bold mb-1">
                  <a href={society.url} target="society.id">{society.name}</a> 
                  { society.genres && society.genres.map((genre:string, i:number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{genre}</span>)) }
                </h6>
                <p className="ml-3 mb-0 news_body_wrap">
                  <span className="font-weight-bold">概要 : </span>{society.description}
                </p>
                <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">運営 : </span>{society.organizer}</p>
                {/* <hr className={(data.listNewsArticlesTables.items.length -1 === i) ? 'd-none' : ''} /> */}
                <hr />
              </div>
              )) : <p>見つかりませんでした</p>)}
              <ClipLoader color={"#0000FF"} loading={!isLoaded} size={50}/>
            </div>

          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}

export default Search;
