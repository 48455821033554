import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 footer-info">
            <a href="https://chemical-base.com" className="scrollto"><img src="/img/logo.png" alt="CHEMICALBASE" className="img-fluid" /></a>
            <p className="pl-md-2">化学者向け情報ポータルサイト　<br className="d-block d-md-none" />CHEMICALBASE(ケミカルベース)</p>
            {/* <p>CHEMICALBASEとは....テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
            </p> */}
          </div>           
          <div className="my-3 footer-links col-6 col-md-4 col-lg-2 m-0">
            {/* <h4>Useful Links</h4> */}
            <ul>
              <li><b>化学品データベース</b></li>
              <li><Link to='/product'>製品名検索</Link></li>
              <li><Link to='/compound'>化合物名検索</Link></li>
              <li><Link to='/supplier'>提供会社一覧</Link></li>
            </ul>
          </div>
          <div className="my-3 footer-links col-6 col-md-4 col-lg-2 m-0">
            {/* <h4>Useful Links</h4> */}
            <ul>
              <li><b>各種サービス</b></li>
              <li><Link to='/news'>ニュース</Link></li>
              <li><Link to='/event'>イベント検索</Link></li>
              <li><Link to='/society'>学会検索</Link></li>
            </ul>
          </div>
          <div className="my-3 footer-links col-6 col-md-4 col-lg-2 m-0">
            {/* <h4>Useful Links</h4> */}
            <ul>
              <li><a href="/">ホーム</a></li>
              <li><a href="/corporate">運営会社</a></li>
              <li><a href="/#">利用規約</a></li>
              <li><a href="/#">プライバシーポリシー</a></li>
            </ul>
          </div>

            {/* <div className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                東京都中央区<br />
                <strong>Phone:</strong> 03-1234-5678<br />
                <strong>Email:</strong> info@example.com<br />
              </p>

              <div className="social-links">
                <a href="/#" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                <a href="/#" className="facebook"><i className="fa-brands fa-facebook"></i></a>
                <a href="/#" className="instagram"><i className="fa-brands fa-instagram"></i></a>
                <a href="/#" className="google-plus"><i className="fa-brands fa-google-plus"></i></a>
                <a href="/#" className="linkedin"><i className="fa-brands fa-linkedin"></i></a>
              </div>

            </div> */}
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; 2023 <strong>ケミカルベース</strong>
      </div>
    </div>
  </footer>
);
export default Footer;