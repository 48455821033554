import React from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

import 'moment/locale/ja';
import './Common.css';

const Corporate = () => {
  document.title = "運営会社 | 化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"
  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main" className="container d-flex my-2">
        <section>
          <div className="row mb-3">
            <div className="col-12 d-flex">
              <div className="col-md-2 col-3 p-2"><b>会社名</b></div>
              <div className="col-md-10 col-9 p-2">株式会社ケミカルベース</div>
            </div>
            <div className="col-12 d-flex p-2">
              <div className="col-md-2 col-3 p-2"><b>取締役</b></div>
              <div className="col-md-10 col-9 p-2">代表取締役　山田　将也</div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-md-2 col-3 p-2"><b>設立</b></div>
              <div className="col-md-10 col-9 p-2">2018年9月</div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-md-2 col-3 p-2"><b>所在地</b></div>
              <div className="col-md-10 col-9 p-2">〒103-0025<br />
                東京都中央区日本橋茅場町一丁目8番1号</div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-md-2 col-3 p-2"><b>事業内容</b></div>
              <div className="col-md-10 col-9 p-2">CHEMICALBASE(ケミカルベース)の運営</div>
            </div>
          </div>
        </section>
      </main >
      <Footer />
    </>
  );
}
export default Corporate;
