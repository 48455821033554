import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import PopularProducts from '../components/PopularProducts';
import { category } from '../constants/constants'

import moment from 'moment';
import 'moment/locale/ja';
import { createDocumentRegistry } from 'typescript';

import {
  Product,
  Document,
  OtherProduct,
  ProductItem,
} from "../types/product"
import ModalMolImage from '../components/ModalMolImage';
import InternalSearch from '../components/InternalSearch';

const Products = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalImage, setModalImage] = useState("");

  const queryStrings = useLocation().search;
  const query = new URLSearchParams(queryStrings);
  const keyword = query.get('q')

  const openModal = (url: string) => {
    setModalImage(url)
    setIsOpen(true);
  }

  useEffect(() => {
    fetchProduct()
  }, [params])

  document.title = (product?.name ? product.name + "の詳細情報 | " : "") + "化学品検索データベース | CHEMICALBASE(ケミカルベース)"

  const fetchProduct = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + '/api/v1/product/' + params.product_id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setProduct(result.res)
          clickResource("product", Number(params.product_id))
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const clickResource = (resource: string, id: number): void => {
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ resource: resource, id: id })
    };

    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/resource/view", opt)
  }

  const clickProduct = (product_id: number): void => {
    clickResource("product", product_id)
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Header />
      <div id="header_space" />
      <section id="company_head" className="container p-0 my-3">
        {/* スマホではここに企業ロゴおよび社名は表示しない */}
        <div className="col-12 d-none d-sm-block p-0">
          <div className="d-flex align-items-center mb-2">
            {product?.supplier.logo_url !== null ? (
              <img src={product?.supplier.logo_url} className="mr-2" alt="logo" style={{ maxHeight: "3rem", maxWidth: "30%" }} />
            ) : ""}
            <h2 className="ml-2 my-0">{product?.supplier.name}</h2>
          </div>
        </div>
        {/* スマホでは検索を作る */}
        <div className="d-block d-sm-none" style={{ zIndex: "100", position: "fixed" }}>
          <button className="btn btn-light py-0 px-1 mx-2" type="button" onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={faBars} className="text-primary" style={{ cursor: "pointer" }} />
          </button>
          <div style={show ? { display: "block" } : { display: 'none' }} className="bg-white col-12">
            <InternalSearch supplier_id={product?.supplier_id} />
          </div>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white pl-2">
            <li className="breadcrumb-item bread-link  pl-4 pl-sm-0"><Link to={`/supplier`}>提供会社</Link></li>
            <li className="breadcrumb-item bread-link"><Link to={`/supplier/${product?.supplier.id}`}>{product?.supplier.name}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{product?.name}</li>
          </ol>
        </nav>
      </section>

      <main id="main" className="container my-2">
        <div className="row">
          {/* スマホではここに企業内検索および人気商品は表示しない */}
          <div className="col-12 col-sm-3 d-none d-sm-block p-2">
            <InternalSearch supplier_id={product?.supplier_id} />
            <PopularProducts supplier_id={Number(product?.supplier_id)} />
          </div>
          {product ? (
            <div id="content" className="col-12 col-sm-9">
              <section id="product-info" className='row'>
                {/* スマホではここに製品名を表示 */}
                <div className="d-block d-sm-none col-12">
                  <h4 className="mb-2">{product.name}</h4>
                </div>
                <div className="d-flex p-2 m-auto col-3" style={{ maxWidth: "170px", maxHeight: "170px" }}>
                  <img src={product.main_compound.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png"} style={{ width: "150px", height: "150px" }} className="m-auto shadow border" width="100%" height="auto" alt=''
                    onClick={() => openModal(product.main_compound.mol_img_url ?? "https://chemicalbase-app-image.s3.ap-northeast-1.amazonaws.com/noimage.png")} />
                </div>
                <div className='col-12 col-sm-8'>
                  {/* <p className="mb-1"><b className="mr-1">CAS No.:</b> 159191-56-7</p> */}
                  {/* PCではここに製品名を表示 */}
                  <div className="d-none d-sm-block">
                    <h4 className="col-12 mb-2 p-0">{product.name}</h4>
                  </div>
                  <div className="row px-3">
                    <div className="col-12 p-0 d-flex">
                      <div className="col-2 px-1 px-md-2 border table-header">化合物名</div>
                      <div className="col-10 px-1 px-md-2 border">{product.main_compound.name_jp}</div>
                    </div>
                    <div className="col-12 p-0 d-flex">
                      <div className="col-2 px-1 px-md-2 border table-header">同義語</div>
                      <div className="col-10 px-1 px-md-2 border">
                        {product.main_compound.aliases?.map((item: string, i: number) => (
                          <>{item}<br /></>
                        ))}
                      </div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">化学式</div>
                      <div className="col-8 px-1 px-md-2 border">{product.main_compound.fomula ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">分子量</div>
                      <div className="col-8 px-1 px-md-2 border">{product.main_compound.weight ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">シリーズ品名</div>
                      <div className="col-8 px-1 px-md-2 border">{product.brand ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">カタログ番号</div>
                      <div className="col-8 px-1 px-md-2 border">{product.catalog_number ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">純度</div>
                      <div className="col-8 px-1 px-md-2 border">{product.purity ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">荷姿</div>
                      <div className="col-8 px-1 px-md-2 border">
                      {product.packing?.map((value: string, i: number) => (
                        <>{value}<br /></>
                      ))}
                      </div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">グレード</div>
                      <div className="col-8 px-1 px-md-2 border">{product.grade ?? "-"}</div>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <div className="col-4 px-1 px-md-2 border table-header">認証</div>
                      <div className="col-8 px-1 px-md-2 border">-</div>
                    </div>
                  </div>
                  <p className="small">※この情報はNITEより引用しております。</p>
                </div>
              </section>

              <section id="product-properties" className="mb-3">
                <p className="mb-3"><b>概要説明 : </b>{product.description}</p>
                <p className="mb-3"><b>用途 : </b>{product.usage}</p>
                <p className="mb-3"><b>詳細 : </b>{product.detail}</p>
              </section>

              <section id="supplier-information" className="mb-5">
                <h5 className="mt-2 mb-1">提供会社情報</h5>
                <hr className="mt-1 mb-2" />
                <p className="mb-1"><b>提供会社名: </b> {product.supplier.name}</p>
                <p className="mb-1"><b>部門名: </b> {product.supplier.division}</p>
                <p className="mb-1"><b>住所: </b> {product.supplier.address}</p>
                <p className="mb-1"><b>URL: </b> <a href={product.supplier.url} className="link" target="{product.supplier.name}">{product.supplier.url}</a></p>
                {/* <p className="mb-1"><b>会社概要: </b>{product.supplier.description}</p> */}
                {/* <p className="mb-1"><b>業種: </b>{product.supplier.category.map((item: string, i: number) => (<span>{category[item]}</span>))}</p> */}
              </section>

              {/* スマホではここに企業ロゴおよび社名は表示する */}
              <div className="col-12 col-sm-3 d-block d-sm-none px-0">
                <div className="d-flex align-items-center mb-2" style={{ height: "3rem" }}>
                  {product?.supplier.logo_url !== null ? (
                    <img src={product?.supplier.logo_url} alt="logo" style={{ maxHeight: "3rem", maxWidth: "30%" }} />
                  ) : ""}
                  <h2 className="m-0">{product?.supplier.name}</h2>
                </div>
              </div>
              <section id="product-documents" className="mb-5">
                <h5 className="mt-2 mb-1">資料</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">資料名</th>
                      <th scope="col">種類</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.documents.map((item: Document, i: number) => (
                      <tr>
                        <th scope="row">{item.name}</th>
                        <td>{item.category}</td>
                        <td><a href={item.url} target={item.url} onClick={() => clickResource("document", item.id)}>資料を見る</a></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <section id="product-another-vendor" className="mb-5">
                <h5 className="mt-2 mb-1">参照元</h5>
                <hr className="mt-1 mb-2" />
                <div className="ml-3">
                  {product.refer_urls?.map((url: string, i: number) => (
                    <p className="mb-1"><a href={url} target={url} onClick={() => clickResource("url" + i.toString, product.id)}>{url}</a></p>
                  ))}
                </div>
              </section>

              <section id="product-another-vendor" className="mb-5">
                <h5 className="mt-2 mb-1">同じ化合物の製品一覧</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th scope="col" className="px-1 pl-md-3 pr-2 pr-md-3">会社名</th>
                      <th scope="col" className=" px-1 px-md-2">製品名</th>
                      <th scope="col" className=" px-1 px-md-2">純度</th>
                      <th scope="col" className=" px-1 px-md-2">グレード</th>
                      <th scope="col" className=" px-1 px-md-2">荷姿</th>
                      {/* <th scope="col" className=" px-1 px-md-2">価格</th> */}
                      {/* <th scope="col" className=" px-1 px-md-2">詳細</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {product.other_products.map((item: OtherProduct, i: number) => (
                        <tr className="rowlink">
                          <td>
                            <Link to={'/product/' + item.id} onClick={() => clickProduct(item.id)} style={{position: "absolute", top:"0",left:"0",right:"0",bottom:"0"}} />
                          </td>
                          <th scope="row">{item.supplier_name ?? "-"}</th>
                          <td>{item.name ?? "-"}</td>
                          <td>{item.purity ?? "-"}</td>
                          <td>{item.grade ?? "-"}</td>
                          <td>
                            {item.packing?.map((value: string, i: number) => (
                              <>{value}<br /></>
                            ))}
                          </td>
                          {/* <td>
                            <FontAwesomeIcon icon={faSearch} className="text-primary" style={{ cursor: "pointer" }} onClick={() => clickProduct(item.id)} />
                          </td> */}
                        </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <section id="product-series" className="mb-5">
                <h5 className="mt-2 mb-1">同じシリーズの製品一覧</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th scope="col" className=" px-1 px-md-2">製品名</th>
                      <th scope="col" className=" px-1 px-md-2">純度</th>
                      <th scope="col" className=" px-1 px-md-2">グレード</th>
                      <th scope="col" className=" px-1 px-md-2">荷姿</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product?.series_products?.map((item: ProductItem, i: number) => (
                      <tr className="rowlink">
                        <td>
                          <Link to={'/product/' + item.id} onClick={() => clickProduct(item.id)} style={{position: "absolute", top:"0",left:"0",right:"0",bottom:"0"}} />
                        </td>
                        <th scope="row">{item.name ?? "-"}</th>
                        <td>{item.purity ?? "-"}</td>
                        <td>{item.grade ?? "-"}</td>
                        <td>
                          {item.packing?.map((value: string, i: number) => (
                            <>{value}<br /></>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <section id="product-series" className="mb-3">
                <h5 className="mt-2 mb-1">同じカテゴリの製品一覧</h5>
                <table className="table">
                  <thead>
                    <tr>
                    <th></th>
                      <th scope="col" className=" px-1 px-md-2">会社名</th>
                      <th scope="col" className=" px-1 px-md-2">製品名</th>
                      <th scope="col" className=" px-1 px-md-2">純度</th>
                      <th scope="col" className=" px-1 px-md-2">グレード</th>
                      <th scope="col" className=" px-1 px-md-2">荷姿</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product?.category_products?.map((item: ProductItem, i: number) => (
                        <tr className="rowlink">
                        <td>
                          <Link to={'/product/' + item.id} onClick={() => clickProduct(item.id)} style={{position: "absolute", top:"0",left:"0",right:"0",bottom:"0"}} />
                        </td>
                        <th scope="row">{item.supplier_name ?? "-"}</th>
                        <td>{item.name ?? "-"}</td>
                        <td>{item.purity ?? "-"}</td>
                        <td>{item.grade ?? "-"}</td>
                        <td>
                          {item.packing?.map((value: string, i: number) => (
                            <>{value}<br /></>
                          ))}
                        </td>
                        {/* <td>
                          <FontAwesomeIcon icon={faSearch} className="text-primary" style={{ cursor: "pointer" }} onClick={() => clickProduct(item.id)} />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

            </div>
          ) : ""}
          {/* スマホではここに人気商品を表示する */}
          <div className="col-12 col-sm-3 d-block d-sm-none">
            <PopularProducts supplier_id={Number(product?.supplier_id)} />
          </div>
          <p className="mt-2">※掲載されている会社名・ロゴは、各社の登録商標または商標です。</p>
          <ModalMolImage isOpen={modalIsOpen} setIsOpen={setIsOpen} imageUrl={modalImage} />
        </div>
      </main>
      {/* <InternalSearch supplier_id={params.supplier_id} /> */}
      <Footer />

    </>
  );

}

export default Products;