import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import 'moment/locale/ja';
import { Society } from "../types/society"
import './Common.css';

const Societies = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [society, setSociety] = useState<Society | null>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchSociety()
  }, [params])

  document.title = (society?.name ? society.name + "の詳細はこちら | " : "" ) + "化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"

  const fetchSociety = () => {
    setIsLoaded(false)
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + '/api/v1/society/' + params.society_id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setSociety(result.res)
          clickResource("society", Number(params.society_id))
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }

  const clickResource = (resource: string, id: number): void => {
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ resource: resource, id: id })
    };
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + "/api/v1/resource/view", opt)
  }

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main" className="container d-flex my-2">
        {society ? (
          <div id="content" className="p-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-white mb-2">
                <li className="breadcrumb-item bread-link"><Link to={`/society`}>学会検索</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{society.name}</li>
              </ol>
            </nav>
            <section id="society-info" className='d-flex'>
              <div className="row px-1">
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">学会名</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.name}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">概要</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.description}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">事務局</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.organizer}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">設立年度</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.since ?? " - "}年</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">分野</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.genres}</div>
                </div>
                <div className="col-12 p-0 d-flex">
                  <div className="col-md-2 col-3 pl-2 pl-md-3 pr-2 pr-md-3 border table-header">タグ</div>
                  <div className="col-md-10 col-9 pl-2 pl-md-3 pr-2 pr-md-3 border">{society.tags}</div>
                </div>
              </div>
            </section>

            <section id="society-properties" className="mb-3">
              <div id="space" className="pt-3 pt-sm-0" style={{ marginTop: "10px" }}></div>
              <div className="text-center">
                <a href={society.url} rel="noreferrer" target="_blank" className="btn btn-primary btn-sm ml-3 px-3 no-wrap" style={{ minWidth: "5rem" }}>学会ページへ移動する</a>
              </div>
            </section>

            <section id="society-categories" className="mb-3">
              <h5 className="text-dark border-bottom border-primary pb-2">同じカテゴリの学会</h5>
              {society.category_societies != undefined && society.category_societies.length ?
                (society.category_societies.map((society: any, i: any) => (
                  <div>
                    <h6 className="font-weight-bold mb-1">
                      <Link to={`/society/${society.id}`} target="_blank">{society.name}</Link>
                      {society.genres && society.genres.map((genre: string, i: number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{genre}</span>))}
                    </h6>
                    <p className="ml-3 mb-0 news_body_wrap">
                      <span className="font-weight-bold">概要 : </span>{society.description}
                    </p>
                    {/* <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">事務局 : </span>{society.organizer}</p> */}
                    {/* <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">設立年度 : </span>{society.since ?? " - "}年</p> */}
                    <hr />
                  </div>
                ))) : (<p>見つかりませんでした</p>)
              }
            </section>

            <section id="society-categories" className="mb-3">
              <h5 className="text-dark border-bottom border-primary pb-2">同じタグの学会</h5>
              {society.tag_societies != undefined && society.tag_societies.length ?
                (society.tag_societies.map((society: any, i: any) => (
                  <div>
                    <h6 className="font-weight-bold mb-1">
                      <Link to={`/society/${society.id}`} target="_blank">{society.name}</Link>
                      {society.genres && society.genres.map((genre: string, i: number) => (<span className="badge-pill badge-primary ml-1 small text-nowrap" key={i}>{genre}</span>))}
                    </h6>
                    <p className="ml-3 mb-0 news_body_wrap">
                      <span className="font-weight-bold">概要 : </span>{society.description}
                    </p>
                    {/* <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">事務局 : </span>{society.organizer}</p> */}
                    {/* <p className="ml-3 mb-0 news_body_wrap"><span className="font-weight-bold">設立年度 : </span>{society.since ?? " - "}年</p> */}
                    <hr />
                  </div>
                ))) : (<p>見つかりませんでした</p>)
              }
            </section>

            <section>
              <div id="space" className="pt-3 pt-sm-0" style={{ marginTop: "10px" }}></div>
              <Link to={`/society`} className="btn btn-primary btn-sm pl-4 pr-3 no-wrap arrow" style={{ minWidth: "5rem" }}>検索結果へ戻る</Link>
            </section>
          </div>
        ) : ""
        }
      </main >
      <Footer />
    </>
  );
}
export default Societies;