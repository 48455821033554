import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Select from 'react-select'

import ClipLoader from "react-spinners/ClipLoader";
import Pagenate from '../components/Pagenation'

const Events = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams( useLocation().search );
  const params = useParams();

  const [keyword, setKeyword] = useState(query.get('q') ?? "");
  const [startDate, setStartDate] = useState(() => {
    const d = new Date()
    return query.get('st') ?? d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)
  })
  const [endDate, setEndDate] = useState(query.get('ed') ?? "");
  const [prefCd, setPrefCd] = useState(query.get('pr') ?? "");
  const [isOnline, setIsOnline] = useState(query.get('online') === "1" ? true : false);
  const [genre, setGenre] = useState(query.get('ge') ?? "");
  const [type, setType] = useState(query.get('ty') ?? "");
  const [currentPage, setCurrentPage] = useState<number>(Number(query.get('p') ?? 0))

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [count, setCount] = useState(1);

  const urlPath = useLocation().pathname

  document.title = "イベント検索 | 化学者向け情報ポータルサイト | CHEMICALBASE(ケミカルベース)"

  const eventParPage = 20;

  useEffect(() => {
    setCurrentPage(Number(query.get("p") ?? 1) - 1)
    
    fetchEvents()

  }, [params])

  const formReset = () => {
    setKeyword("")
    setStartDate("")
    setEndDate("")
    setPrefCd("")
    setGenre("")
    setType("")
    setIsOnline(false)

    query.delete("q")
    query.delete("st")
    query.delete("ed")
    query.delete("pr")
    query.delete("online")
    query.delete("ge")
    query.delete("ty")

  }

  const fetchEvents = () => {
    document.getElementById('searchKeyword')?.blur();
    setIsLoaded(false);
    fetch(process.env.REACT_APP_CHEMICALBASE_API_BASEURL + `/api/v1/event?c=${eventParPage}&` + query.toString())
    //   + (currentPage !== 0 ? '&p=' + currentPage : '')
    //   + (keyword ? '&q=' + keyword : '')
    //   + (startDate ? '&st=' + startDate : '')
    //   + (endDate ? '&ed=' + endDate : '')
    //   + (prefCd !== '00' ? '&pr=' + prefCd : '')
    //   + (isOnline ? '&online=1' : '')
    //   + (genre ? '&ge=' + genre : '')
    // )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result.res);
          setCount(result.count);
          window.scrollTo(0, 0);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  const searchEvent = () => {

    !keyword ? query.delete("q") : query.set("q", keyword)
    !startDate ? query.delete("st") : query.set("st", startDate)
    !endDate ? query.delete("ed") : query.set("ed", endDate)
    !prefCd ? query.delete("pr") : query.set("pr", prefCd)
    !isOnline ? query.delete("online") : query.set("online", "1")
    genre === "" ? query.delete("ge") : query.set("ge", genre)
    type === "" ? query.delete("ty") : query.set("ty", type)
    query.delete("p")

    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
    
  }

  const pageChange = (data: any) => {
    data.selected === 0 ? query.delete("p") : query.set("p", String(data.selected + 1))
    navigate({pathname: urlPath, search: "?" + query.toString() })
    window.scrollTo(0, 0);
  }

  // const getToday = (): string => {
  //   const d = new Date()
  //   return d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)
  // }

  const formattedDateTime = (datetime: string): string => {
    const d = new Date(Date.parse(datetime));
    return d.getFullYear().toString() + "年 " +
      (d.getMonth() + 1).toString() + "月 " +
      d.getDate().toString() + "日 ";
  }

  const prefs = [
    '指定なし', '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県',
    '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県',
    '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県',
    '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
    '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県', 'オンライン'
  ]
  const selectPrefs = [
    { value: "01", label: "北海道" },
    { value: "02", label: "青森県" },
    { value: "03", label: "岩手県" },
    { value: "04", label: "宮城県" },
    { value: "05", label: "秋田県" },
    { value: "06", label: "山形県" },
    { value: "07", label: "福島県" },
    { value: "08", label: "茨城県" },
    { value: "09", label: "栃木県" },
    { value: "10", label: "群馬県" },
    { value: "11", label: "埼玉県" },
    { value: "12", label: "千葉県" },
    { value: "13", label: "東京都" },
    { value: "14", label: "神奈川県" },
    { value: "15", label: "新潟県" },
    { value: "16", label: "富山県" },
    { value: "17", label: "石川県" },
    { value: "18", label: "福井県" },
    { value: "19", label: "山梨県" },
    { value: "20", label: "長野県" },
    { value: "21", label: "岐阜県" },
    { value: "22", label: "静岡県" },
    { value: "23", label: "愛知県" },
    { value: "24", label: "三重県" },
    { value: "25", label: "滋賀県" },
    { value: "26", label: "京都府" },
    { value: "27", label: "大阪府" },
    { value: "28", label: "兵庫県" },
    { value: "29", label: "奈良県" },
    { value: "30", label: "和歌山県" },
    { value: "31", label: "鳥取県" },
    { value: "32", label: "島根県" },
    { value: "33", label: "岡山県" },
    { value: "34", label: "広島県" },
    { value: "35", label: "山口県" },
    { value: "36", label: "徳島県" },
    { value: "37", label: "香川県" },
    { value: "38", label: "愛媛県" },
    { value: "39", label: "高知県" },
    { value: "40", label: "福岡県" },
    { value: "41", label: "佐賀県" },
    { value: "42", label: "長崎県" },
    { value: "43", label: "熊本県" },
    { value: "44", label: "大分県" },
    { value: "45", label: "宮崎県" },
    { value: "46", label: "鹿児島県" },
    { value: "47", label: "沖縄県" }
  ]
  const selectGenres = [
    { value: "", label: "指定なし" },
    { value: "食品", label: "食品" },
    { value: "ゴム", label: "ゴム" },
    { value: "化粧品", label: "化粧品" },
    { value: "塗料", label: "塗料" },
    { value: "製薬", label: "製薬" },
    { value: "農薬", label: "農薬" },
    { value: "プラスチック", label: "プラスチック" },
    { value: "繊維", label: "繊維" }
  ]

  const selectTypes = [
    { value: "", label: "指定なし" },
    { value: "展示会", label: "展示会" },
    { value: "学会", label: "学会" },
    { value: "イベント・セミナー", label: "イベント・セミナー" }
  ]

  return (
    <>
      <Header />
      <section id="header_space" />
      <main id="main">

        <section id="papers">
          <div className="container">
            <h4 className="text-dark font-weight-bold border-bottom border-primary pb-2">イベント検索</h4>
            {/* 検索条件部 */}
            <div id="search" className="mb-4 mx-auto p-0 col-12 col-sm-10">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <p className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">詳細検索</p>
                </div>
              </nav>
              <div className="tab-content border border-top-0" id="nav-tabContent">
                <div className="tab-pane fade show active p-3" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); setCurrentPage(0); fetchEvents() }}>
                    <div className="d-flex align-items-center py-1">
                      <div style={{ width: "8rem" }}>
                        <b>キーワード : </b>
                      </div>
                      <div className="input-group input-group-sm" style={{ maxWidth: "20rem" }}>
                        <input type="text" className="form-control" id="searchKeyword" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-1">
                      <div style={{width:"8rem"}}>
                        <b>開催日: </b>
                      </div>
                      <div className="input-group input-group-sm align-items-center" style={{maxWidth:"20rem"}}>
                        <input type="date" className="form-control" id="dateStart" 
                          onChange={(e) => {
                            setStartDate(e.target.value)
                            if(e.target.value > endDate && endDate !== "") {
                              setEndDate(e.target.value)
                            }
                          }}
                          value={startDate}
                        />
                        <span className="mx-3">〜</span>
                        <input type="date" className="form-control" id="dateEnd"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                          min={startDate}
                        />
                      </div>
                    </div>

                      <div className="d-flex align-items-center py-1">
                        <div style={{ width: "8rem" }}>
                          <b>開催場所: </b>
                        </div>
                      <div className="input-group input-group-sm" style={{ maxWidth: "20rem" }}>
                        <Select
                          options={selectPrefs}
                          defaultValue={prefCd.split("-").map(el => selectPrefs.find(x => x.value === el))}
                          value={prefCd.split("-").map(el => selectPrefs.find(x => x.value === el))}
                          onChange={(e) => {
                            const prefArr = e ? e.map(function (item) { return item ? item['value'] : null }) : null;
                            setPrefCd(prefArr ? prefArr.join('-') : "");
                          }}
                          className="w-100"
                          placeholder="指定なし"
                          id="selectPref"
                          name="selectPref"
                          isMulti />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-1">
                      <div style={{ width: "8rem" }}>
                      </div>
                      <div className="input-group input-group-sm ml-4" style={{ maxWidth: "20rem" }}>
                        <input className="form-check-input" type="checkbox" value="on" id="isOnline" checked={isOnline} onChange={(e) => setIsOnline(e.target.checked)} />
                        <label className="my-0" htmlFor="isOnline">オンライン開催</label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-1">
                      <div style={{ width: "8rem" }}>
                        <b>分野: </b>
                      </div>
                      <div className="input-group input-group-sm" style={{ maxWidth: "20rem" }}>
                        <Select
                          options={selectGenres}
                          defaultValue={selectGenres.find(el => el.value === genre)}
                          value={selectGenres.find(el => el.value === genre)}
                          onChange={(e) => {
                            setGenre(e ? e.value : "");
                          }}
                          className="w-100"
                          placeholder="指定なし"
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-1">
                      <div style={{ width: "8rem" }}>
                        <b>種類: </b>
                      </div>
                      <div className="input-group input-group-sm" style={{ maxWidth: "20rem" }}>
                        <Select
                          options={selectTypes}
                          defaultValue={selectTypes.find(el => el.value === type)}
                          value={selectTypes.find(el => el.value === type)}
                          onChange={(e) => {
                            setType(e ? e.value : "");
                          }}
                          className="w-100"
                          placeholder="指定なし"
                        />
                      </div>
                    </div>
                    <div className="d-flex mx-auto w-100 w-sm-50 align-items-center justify-content-around py-1">
                      <input type="submit" className="btn btn-success" style={{ minWidth: "8rem" }} value="検索"
                        onClick={() => { searchEvent() }}
                      />
                      <input type="reset" className="btn btn-outline-success" style={{ minWidth: "8rem" }} value="リセット"
                        onClick={() => formReset()} />
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                </div>
              </div>
            </div>

            {/* 検索結果部 */}
            <h5 className="text-dark font-weight-bold border-bottom border-primary pb-2">イベントカレンダー</h5>
            <div>
              <div className='row'>
                <div className='d-none d-sm-block col-sm-4 col-md-3 text-center'>
                  <b>開催期間</b>
                </div>
                <div className='d-none d-sm-block col-sm-8 col-md-4 text-center'>
                  <b>主催名/イベント名</b>
                </div>
                <div className='d-none d-md-block col-md-2 text-center'>
                  <b>都道府県</b>
                </div>
                <div className='d-none d-md-block col-md-2 text-center'>
                  <b>会場</b>
                </div>
              </div>
              <hr />
            </div>
            <div id="latest" className="mb-3 w-100">
              <div className="text-center mt-5">
                <ClipLoader
                  color="blue"
                  loading={!isLoaded}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
              {data.length ?
                (data.map((event: any, i: any) => (
                  <div className={isLoaded ? '' : 'd-none'}>
                    <div className='row'>
                      <div className='col-12 col-sm-4 col-md-3'>
                        <b className='d-sm-none'>開催期間: </b>
                        {formattedDateTime(event.start_date) + "〜" + formattedDateTime(event.end_date)}
                      </div>
                      <div className='col-12 col-sm-8 col-md-4'>
                        <h6 className="font-weight-bold mb-1">
                          <Link to={`/event/${event.id}`} className="bread-link">{event.title}</Link>
                        </h6>
                      </div>
                      <div className='d-none d-sm-block col-sm-4 d-md-none'>
                      </div>
                      <div className='col-12 col-sm-3 col-md-2'>
                        <b className='d-sm-none'>都道府県: </b>
                        {prefs[Number(event.pref_cd)]}
                      </div>
                      <div className='col-12 col-sm-5 col-md-2'>
                        <b className='d-sm-none'>会場: </b>
                        {event.location}
                      </div>
                    </div>
                    <hr />
                  </div>
                ))) : (<p>見つかりませんでした</p>)
              }

              {data.length ? (
                <Pagenate className="mx-auto"
                  pageCount={Math.ceil(count / eventParPage)}
                  onPageChange={pageChange} //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                  forcePage={currentPage}
                />
              ) : ''

              }
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Events;
